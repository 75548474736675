import React from 'react'

import Layout from '../components/Layout'
import SectionHeaderImage from '../components/common/SectionHeaderImage'
import image_background from '../images/solutions/fiscal.png'
import FooterContact from './../components/FooterContact'
import PageContentImpuestos from '../components/Solutions/Pages/Impuestos'

const Fiscal = props => (
  <Layout
    title="Servicio Fiscal"
    description="El entorno fiscal en México año con año se torna más complejo y especializado, es por eso que a través de nuestra Solución Fiscal te otorgamos una garantía de 0 multas desde el momento en que contratas con nosotros."
    {...props}
  >
    <SectionHeaderImage image={image_background} position="90% 0" />
    <PageContentImpuestos />
    <FooterContact />
  </Layout>
)

export default Fiscal
